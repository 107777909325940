<template>
    <v-row>
        <v-col cols="12">
            <div class="d-flex">
                <v-btn
                icon
                color="accent darken-3"
                class="align-self-center"
                link
                :to="computedReturnRoute"
            >
                <v-icon class="mr-2">
                    fa-solid fa-chevron-left
                </v-icon>
            </v-btn>
            <h2 class="main-heading">
                {{ $t(`Admin.CreateEditDih.${heading}`) }}
            </h2>
            </div>
            <base-card>
                <template #content>
                    <v-card-text>
                        <v-row>
                            <v-col
                                cols="12"
                                md="7"
                            >
                                <text-input
                                    :input-value.sync="formValues.title"
                                    :current-value="dih.title"
                                    :in-valid.sync="isTitleInalid"
                                    :label="$t(`Admin.CreateEditDih.form.title`)"
                                ></text-input>

                                <text-area
                                    :input-value.sync="formValues.description"
                                    :current-value="dih.description"
                                    :in-valid.sync="isDescriptionInalid"
                                    :label="$t(`Admin.CreateEditDih.form.description`)"
                                ></text-area>

                                <upload-input
                                    :image-url.sync="formValues.image_url"
                                    :current-image="dih.image_url"
                                    :in-valid.sync="isImageUrlInalid"
                                ></upload-input>
                                <select-input
                                    :input-value.sync="formValues.innovation_fields"
                                    :current-value="dih.innovation_fields"
                                    :in-valid.sync="isInnovationInalid"
                                    :items="innovations"
                                    :label="$t(`Admin.CreateEditDih.form.innovations`)"
                                ></select-input>
                                <select-input
                                    :input-value.sync="formValues.services"
                                    :current-value="dih.services"
                                    :items="services"
                                    :in-valid.sync="isServicesInalid"
                                    :label="$t(`Admin.CreateEditDih.form.services`)"
                                ></select-input>
                                <select-input
                                    :input-value.sync="formValues.technology_fields"
                                    :current-value="dih.technology_fields"
                                    :items="technologies"
                                    :in-valid.sync="isTechnologyInalid"
                                    :label="$t(`Admin.CreateEditDih.form.technologies`)"
                                ></select-input>
                            </v-col>
                            <v-col
                                cols="12"
                                md="5"
                            >
                                <span>{{ $t(`Admin.CreateEditDih.form.selectLocation`) }}</span>
                                <select-location
                                    :coordinates.sync="formValues.coordinates"
                                    :marker-coords="dih.coordinates"
                                    :map-options="mapOptions"
                                    :has-coords.sync="isLocationInalid"
                                ></select-location>
                            </v-col>
                        </v-row>
                    </v-card-text>
                    <v-card-actions class="mt-8">
                        <v-row>
                            <v-col class="d-flex justify-end">
                                <v-btn
                                    depressed
                                    color="primary"
                                    :disabled="disabled"
                                    :loading="hasParamsId() ? handleEditDihStatus_Pending : handleCreateDihStatus_Pending"
                                    @click="choseAction()"
                                >
                                    {{ actionMethodLabel }}
                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-card-actions>
                </template>
            </base-card>
        </v-col>
    </v-row>
</template>

<script>
import { apiStatus } from '@/api/constants/apiStatus'
import { apiStatusComputed } from "@/api/helpers/computedApiStatus"
import { withAsync } from "@/helpers/withAsync"
import { getSingleDih, createDih, updateDih } from "@/api/adminApi.js"
import { getInnovations, getServices, getTechnologyFields } from "@/api/collectionsApi.js"

import TextInput from "@/components/Inputs/TextInput.vue";
import TextArea from "@/components/Inputs/TextArea.vue";
import UploadInput from "@/components/Inputs/UploadInput.vue";
import SelectInput from "@/components/Inputs/SelectInput.vue";
import SelectLocation from "@/components/Inputs/SelectLocation.vue";

import { mapGetters } from 'vuex'
export default {
    name: "CreateEditDih",

    components: {
        TextInput,
        TextArea,
        UploadInput,
        SelectInput,
        SelectLocation
    },

    data() {
        return {
            dih: {},
            innovations: [],
            services: [],
            technologies: [],
            mapOptions: {
                zoomControl:        false,
                trackResize:        true,
                maxBoundsViscosity: 1.0,
                doubleClickZoom:    false,
            },
            formValues: {},
            isTitleInalid: this.hasParamsId() ? false : true,
            isDescriptionInalid: this.hasParamsId() ? false : true,
            isImageUrlInalid: this.hasParamsId() ? false : true,
            isInnovationInalid: this.hasParamsId() ? false : true,
            isServicesInalid: this.hasParamsId() ? false : true,
            isTechnologyInalid: this.hasParamsId() ? false : true,
            isLocationInalid: this.hasParamsId() ? false : true,
            handleCreateDihStatus: apiStatus.Idle,
            handleEditDihStatus: apiStatus.Idle,
        };
    },

    computed: {
        ...apiStatusComputed(["handleCreateDihStatus", "handleEditDihStatus"]),
        ...mapGetters({
            isAdmin: "auth/userIsAdmin",
        }),

        computedReturnRoute(){
            if(this.isAdmin) return ({name: "Dih"})
            else return ({name: "MyListings"});
        },
        heading() {
            if(this.hasParamsId()) {
                return "editHeading"
            }
            return "createHeading"
        },
        actionMethodLabel() {
            return this.hasParamsId() ? "Update DIH" : "Create DIH";
        },
        //Form validation
        disabled() {
            if (this.hasParamsId()) {
                for (const key in this.formValues) {
                    if (this.formValues.hasOwnProperty(key)) {
                        return this.handleValidation()
                    }
                }
                return true
            }
            return this.handleValidation()
        },
    },

    methods: {
        async handleSingleDih() {
            const payload = {
                id: this.$route.params.id,
            };

            const { response, error } = await withAsync(getSingleDih, payload);

            this.dih = response.data.data;
        },

        async handleInnovations() {
            const { response, error } = await withAsync(getInnovations);

            if (error) {
                return;
            }

            this.innovations = response.data.data;
        },

        async handleServices() {
            const { response, error } = await withAsync(getServices);

            if (error) {
                return;
            }

            this.services = response.data.data;
        },

        async handleTechnology() {
            const { response, error } = await withAsync(getTechnologyFields);

            if (error) {
                return;
            }

            this.technologies = response.data.data;
        },

        async handleCreateDih() {
            this.handleCreateDihStatus = apiStatus.Pending
            let data = new FormData();
            data.append("title", this.formValues.title);
            data.append("description", this.formValues.description);
            data.append("image_url", this.formValues.image_url);
            data.append("innovation_fields", this.formValues.innovation_fields);
            data.append("services", this.formValues.services);
            data.append("technology_fields", this.formValues.technology_fields);
            data.append("lat", this.formValues.coordinates.lat);
            data.append("lng", this.formValues.coordinates.lng);

            const { response, error } = await withAsync(createDih, data);

            if (error) {
                this.handleCreateDihStatus = apiStatus.Error
                return;
            }
            this.handleCreateDihStatus = apiStatus.Success;
            if(this.isAdmin) this.$router.push({name: "Dih"});
            else this.$router.push({name: "MyListings"});
        },
        async handleEditDih() {
            this.handleEditDihStatus = apiStatus.Pending
            let data = new FormData();
            for (const [key, value] of Object.entries(this.formValues)) {
                if (key == "coordinates") {
                    for (const [key, value] of Object.entries(value)) {
                        data.append([key], value);
                    }
                }else {
                    data.append([key], value);
                }
            }

            const payload = {
                id: this.$route.params.id,
                data: data
            }

            const { response, error } = await withAsync(updateDih, payload);

            if (error) {
                this.handleEditDihStatus = apiStatus.Error;
                return;
            }
            this.handleEditDihStatus = apiStatus.Success;
            if(this.isAdmin) this.$router.push({name: "Dih"});
            else this.$router.push({name: "MyListings"});
        },

        choseAction() {
            this.hasParamsId() ? this.handleEditDih() : this.handleCreateDih();
        },

        handleValidation() {
            if (this.isTitleInalid) {
                return true
            }
            if (this.isDescriptionInalid) {
                return true
            }
            if (this.isImageUrlInalid) {
                return true
            }
            if (this.isInnovationInalid) {
                return true
            }
            if (this.isServicesInalid) {
                return true
            }
            if (this.isTechnologyInalid) {
                return true
            }
            if (this.isLocationInalid) {
                return true
            }
            return false
        },

        hasParamsId() {
            return this.$route.params.id ? true : false;
        }
    },

    created() {
        this.apiStatus = apiStatus;
        if (this.$route.params.id) {
            this.handleSingleDih();
        }
        this.handleInnovations();
        this.handleServices();
        this.handleTechnology();
    }
}
</script>

<style lang="scss" scoped>
.form-divider {
    border-width: 1px;
}
</style>